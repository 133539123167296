import { Fragment, useEffect, useState } from 'react'
import styles from './App.module.css'
import FiberCanvas from './FiberCanvas'
import './index.css'
import { isWebGLAvailable } from './utils/webgl'

function App() {
    const [webGLAvailable, setWebGLAvailable] = useState<boolean | null>(null)

    useEffect(() => {
        setWebGLAvailable(isWebGLAvailable())
    }, [])

    useEffect(() => {
        console.log(webGLAvailable)
    }, [webGLAvailable])

    if (webGLAvailable === null) {
        return <Fragment />
    }

    if (webGLAvailable === false) {
        return (
            <main>
                <div className={styles.fallbackContainer}>
                    <h3>
            You need to enable WebGL to see my porforlio. 
                        <a href="mailto:contact@yoannlatzer.fr">
                            <h2>email me: contact@yoannlatzer.fr</h2>
                        </a>
                    </h3>
                </div>
            </main>
        )
    }
    return (
        <main>
            <div className={styles.mainContainer}>
                <h1>yoannlatzer.com/.fr</h1>
                <h1>under construction</h1>
                <a href="mailto:contact@yoannlatzer.fr">
                    <h2>email me: contact@yoannlatzer.fr</h2>
                </a>
                <a
                    href="https://www.linkedin.com/in/yoann-m-e-latzer-45a51638/"
                    target="_blank"
                >
                    <h2>visit my linkedin</h2>
                </a>
            </div>
            <div className={styles.canvasContainer}>
                <FiberCanvas />
            </div>
        </main>
    )
}

export default App
