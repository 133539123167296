import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { Fragment, Suspense, useRef } from 'react'
import Logo from './3d/Logo'

export default function FiberCanvas() {
    const ref = useRef<any>()
    return (
        <Canvas>
            <Suspense fallback={<Fragment />}>
                <ambientLight />
                <pointLight position={[0, 40, 10]} />
                <Logo />
                <OrbitControls ref={ref} autoRotate />
            </Suspense>
        </Canvas>
    )
}
